<template>
<div>
<!--  未开票弹窗  -->
  <a-modal
      @cancel="cancel"
      title="提示"
      :visible="visible"
      :footer="null"
      width="360px"
  >
    <div class="model">
      <div class="message">
        <div class="title">截至目前未开发票总金额：{{money}}元</div>
        <div style="margin-top: 5px;margin-bottom: 40px;color: red">变更审核状态中将无法开具发票</div>
        <div class="btn">
          <a-button type="primary"  @click="continueChange()">继续变更</a-button>
          <a-button @click="issueInvoice" type="primary" style="margin-left: 100px">开具发票</a-button>
        </div>
      </div>
    </div>
  </a-modal>
</div>
</template>

<script>
export default {
  name: "MakeOutInvoice",
  props:{
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: false,
    },
    money: {
      type: Number,
      default: 0,
    },
  },
  methods:{
    cancel(){
      let flag = false;
      this.$emit("closeMain", flag); //子组件向父组件传值（开关）
    },
    continueChange(){ //继续变更
      let flag = false;
      this.$emit("closeMain", flag); //子组件向父组件传值（开关）
      this.$emit("checkList", true); //继续变更
    },
    issueInvoice(){ //开具发票
      let flag = false;
      this.$emit("closeMain", flag); //子组件向父组件传值（开关）
      this.$emit("checkList", false); //开具发票
    }
  }
}
</script>

<style scoped lang="scss">
.model{
  width: 100%;
  //display: flex;
  text-align: center;
  .message{
    margin-top: 2px;
    //margin-left: 15px;
    .title{
      color: rgba(0,0,0,);
    }
    .btn{

    }
  }
}
</style>
