<template>
  <div class="box">
    <div class="item">
      <div class="title">登录密码</div>
      <div class="operation">
        <!-- 8位为强度为低，9-10位强度为中，>10为强度为高 -->
        <div>
          当前密码：{{ person.password }}
          <!-- <span v-if="temp.type == ''">未设置</span> -->
          <!-- <span style="color: red" v-if="temp.type == '弱'">{{
            temp.type
          }}</span>
          <span style="color: rgb(203, 187, 13)" v-if="temp.type == '中'">{{
            temp.type
          }}</span>
          <span style="color: green" v-if="temp.type == '强'">{{
            temp.type
          }}</span> -->
        </div>
        <div @click="openPsd">
          <a v-if="person.password == '未设置'">设置</a>
          <a v-else>修改</a>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="title">登录绑定手机号</div>
      <div class="operation">
        <div>已绑定手机号：{{ person.tel }}</div>
        <div @click="openTel"><a>修改</a></div>
      </div>
    </div>
    <div class="item">
      <div class="title">绑定邮箱</div>
      <div class="operation">
        <div>{{ person.email }}</div>
        <div @click="openEmail">
          <a v-if="person.email == '未设置'">设置</a>
           <a v-else>修改</a>
        </div>
      </div>
    </div>

    <!-- 登录密码弹窗 -->
    <a-modal
      v-model="password"
      title="登录密码"
      @ok="psdOk"
      @cancel="psdCancel"
    >
      <a-form-model
        ref="psdForm"
        :model="passwordForm"
        :rules="rulePsd"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="手机号" prop="tel">
          <a-input
            disabled
            class="input"
            v-model="passwordForm.tel"
            placeholder="请输入"
          />
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="code">
          <a-input
            v-model="passwordForm.code"
            class="sign-up-input"
            placeholder="请输入验证码"
          >
            <a-tooltip slot="suffix">
              <a
                size="small"
                type="primary"
                @click="phoneCodeLoginQ"
                :disabled="phoneCodeLoginDisabledQ"
                >{{ phoneCodeLoginTextQ }}</a
              >
            </a-tooltip>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="登录密码" prop="loginPsd">
          <a-input
            class="input"
            v-model="passwordForm.loginPsd"
            placeholder="请输入(8~16位的字母、数字组合)"
          />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="confirmPsd">
          <a-input
            class="input"
            v-model="passwordForm.confirmPsd"
            placeholder="请输入(8~16位的字母、数字组合)"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 修改登录手机号 -->
    <a-modal v-model="telWord" title="修改登录手机号">
      <a-form-model
        ref="telForm"
        :model="telForm"
        :rules="ruleTel"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="手机号" prop="tel" v-if="btnshow">
          <a-input
            class="input"
            v-model="telForm.tel"
            placeholder="请输入"
            disabled
          />
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="code" v-if="btnshow">
          <!-- <a-input class="input" v-model="telForm.code" placeholder="请输入" /> -->
          <a-input
            v-model="telForm.code"
            class="sign-up-input"
            placeholder="请输入验证码"
          >
            <a-tooltip slot="suffix">
              <a
                size="small"
                type="primary"
                @click="oldPhoneNum"
                :disabled="phoneCodeLoginDisabledOldPhone"
                >{{ phoneCodeLoginTextOldPhone }}</a
              >
            </a-tooltip>
          </a-input>
          <p>注：原手机号无法接收验证码，请联系客服</p>
          <!-- 下一步 -->
        </a-form-model-item>
        <a-form-model-item label="新手机号" prop="Newtel" v-if="!btnshow">
          <a-input
            class="input"
            v-model="telForm.Newtel"
            placeholder="请输入"
          />
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="Newcode" v-if="!btnshow">
          <a-input
            v-model="telForm.Newcode"
            class="sign-up-input"
            placeholder="请输入验证码"
          >
            <a-tooltip slot="suffix">
              <a
                size="small"
                type="primary"
                @click="NewPhoneNum"
                :disabled="NewPhoneshow"
                >{{ NewText }}</a
              >
            </a-tooltip>
          </a-input>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="telCancel">取消</a-button>
        <a-button v-show="btnshow" type="primary" @click="nextWork"
          >下一步</a-button
        >
        <a-button v-show="!btnshow" type="primary" @click="telOk"
          >确定</a-button
        >
      </template>
    </a-modal>

    <!-- 绑定邮箱 -->
    <a-modal
      v-model="EmailWord"
      title="绑定邮箱"
      @ok="EmailOk"
      @cancel="EmailCancel"
    >
      <a-form-model
        ref="EmailForm"
        :model="EmailForm"
        :rules="ruleEmail"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="邮箱" prop="email">
          <a-input
            class="input"
            v-model="EmailForm.email"
            placeholder="请输入邮箱"
          />
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="code">
          <!-- <a-input
            class="input"
            v-model="EmailForm.code"
            placeholder="请输入"
          /> -->
          <a-input
            v-model="EmailForm.code"
            class="sign-up-input"
            placeholder="请输入验证码"
          >
            <a-tooltip slot="suffix">
              <a
                size="small"
                type="primary"
                @click="sendEmailCode"
                :disabled="phoneCodeLoginDisabledEmail"
                >{{ phoneCodeLoginTextEmail }}</a
              >
            </a-tooltip>
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getSafe,
  smsRight,
  editPsd,
  getEmailCode,
  updateEmail,
  updateTel,
  NewSendCode,
} from "../../service/columnmanageapi";
import {
  getAuthenticationInformation,
  getSubmitCertification,
  sendSMS,
  submitCertification,
} from "@/service/authentication";
export default {
  name: "safeView",
  data() {
    return {
      timer: null,
      timer1: null,
      timer2: null,
      timer3: null,
      btnshow: true,
      NewPhoneshow: false,
      NewText: "获取验证码",
      person: {},
      password: false,
      telWord: false,
      EmailWord: false,
      passwordForm: {},
      telForm: {},
      EmailForm: {},
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      phoneCodeLoginTextQ: "获取验证码",

      phoneCodeLoginDisabledQ: false,

      phoneCodeLoginTextOldPhone: "获取验证码",
      phoneCodeLoginDisabledOldPhone: false,

      phoneCodeLoginTextEmail: "获取验证码",
      phoneCodeLoginDisabledEmail: false,

      psdType: "",
      temp: {},
      rulePsd: {
        code: [{ required: true, message: "请输入", trigger: "blur" }],
        loginPsd: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
            message: "请输入8~16位字母、数字组合",
            trigger: "blur",
          },
        ],
        confirmPsd: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
            message: "请输入8~16位字母、数字组合",
            trigger: "blur",
          },
        ],
      },
      ruleTel: {
        tel: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
            message: "请输入8~16为字母、数字组合",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入", trigger: "blur" }],
        Newtel: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        Newcode: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      ruleEmail: {
        email: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: "请输入正确的邮箱号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  created() {
    this.load();
    // this.getType();
  },
  methods: {
    load() {
      getSafe().then((res) => {
        if (res.code === 200) {
          this.person = res.data;
        }
      });
    },
    // 打开登录密码弹窗
    openPsd() {
      this.password = true;
      this.passwordForm = this.person;
    },
    async phoneCodeLoginQ() {
      //验证码登录-获取验证码
      let data = {
        tel: this.passwordForm.tel,
      };
      const response = await sendSMS(data);
      if (response.code === 0) {
        this.$message.success("发送成功");
        this.phoneCodeLogin60sQ(); // 调用验证码倒计时
      } else {
        this.$message.warning(response.message);
      }
    },
    // 登录密码倒计时 --- 登录密码
    phoneCodeLogin60sQ() {
      // 验证码登录-获取验证码-60s倒计时
      this.phoneCodeLoginDisabledQ = true;
      let count = 120;
      this.timer = setInterval(() => {
        count--;
        this.phoneCodeLoginTextQ = count + "s";
        if (count === 0) {
          clearInterval(this.timer);
          this.phoneCodeLoginTextQ = "重新获取";
          this.phoneCodeLoginDisabledQ = false;
        }
      }, 1000);
    },
    // 确定修改登录密码
    psdOk() {
      this.$refs.psdForm.validate(async (valid) => {
        if (valid) {
          // 两次密码正确
          if (this.passwordForm.loginPsd === this.passwordForm.confirmPsd) {
            let data = {
              tel: this.passwordForm.tel,
              smsCode: this.passwordForm.code,
              password: this.passwordForm.loginPsd,
            };

            editPsd(data).then((res) => {
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.password = false;
                this.phoneCodeLoginTextQ = "获取验证码";
                this.person = {};
                this.load();
              } else {
                this.$message.warning("修改失败");
                this.$refs.psdForm.resetFields();
              }
            });
          } else {
            this.$message.warning("密码输入不一致");
          }
        }
      });
    },
    psdCancel() {
      this.password = false;
      this.$refs.psdForm.resetFields();
      this.passwordForm = {};
      this.load();
      this.phoneCodeLoginTextQ = "获取验证码";
    },

    openTel() {
      this.telWord = true;
      this.telForm = this.person;
    },

    // 原手机号
    async oldPhoneNum() {
      //验证码登录-获取验证码
      let data = {
        tel: this.telForm.tel,
      };
      const response = await sendSMS(data);
      if (response.code === 0) {
        this.$message.success("发送成功");
        this.oldphoneCodeLogin60sQ(); // 调用验证码倒计时
      } else {
        this.$message.warning(response.message);
      }
    },

    // 原始验证码倒计时
    oldphoneCodeLogin60sQ() {
      // 验证码登录-获取验证码-60s倒计时
      this.phoneCodeLoginDisabledOldPhone = true;
      let count = 120;
      this.timer2 = setInterval(() => {
        count--;
        this.phoneCodeLoginTextOldPhone = count + "s";
        if (count === 0) {
          clearInterval(this.timer2);
          this.phoneCodeLoginTextOldPhone = "重新获取";
          this.phoneCodeLoginDisabledOldPhone = false;
        }
      }, 1000);
    },

    // 新手机号
    async NewPhoneNum() {
      let data = {
        tel: this.telForm.Newtel,
      };
      const response = await NewSendCode(data);
      if (response.code === 0) {
        this.$message.success("发送成功");
        this.phoneCodeLogin(); // 调用验证码倒计时
      } else {
        this.$message.warning(response.message);
      }
    },

    // 新手机号倒计时
    phoneCodeLogin() {
      this.NewPhoneshow = true;
      let count = 120;
      this.timer2= setInterval(() => {
        count--;
        this.NewText = count + "s";
        if (count === 0) {
          clearInterval(this.timer2);
          this.NewText = "重新获取";
          this.NewPhoneshow = false;
        }
      }, 1000);
    },

    // 点击下一步
    nextWork() {
      this.$refs.telForm.validateField("code", async (valid) => {
        if (!valid) {
          let data = {
            tel: this.telForm.tel,
            smsCode: this.telForm.code,
          };
          smsRight(data).then((res) => {
            this.btnshow = !this.btnshow;
            this.phoneCodeLoginDisabledQ = false;
          });
        }
      });
    },
    telOk() {
      this.$refs.telForm.validate(async (valid) => {
        if (valid) {
          let data = {
            tel: this.telForm.Newtel,
            smsCode: this.telForm.Newcode,
          };
          updateTel(data).then((res) => {
            if (res.code == 200) {
              this.$message.success("修改成功");
              this.$refs.telForm.resetFields();
              this.telWord = false;
              this.telForm = {};
              this.load();
            } else {
              this.$message.warning("修改失败");
            }
          });
        }
      });
    },
    telCancel() {
      this.load();
      this.telWord = false;
      this.btnshow = true;
      this.$refs.telForm.resetFields();
      this.telForm = {};
      this.phoneCodeLoginTextQ = "获取验证码";
      this.NewText = "获取验证码";
    },

    // 打开邮箱
    openEmail() {
      this.EmailWord = true;
      this.load();
      this.EmailForm = this.person;
      if (this.EmailForm.email == "未设置") {
        this.EmailForm.email = "";
      }
    },

    // 获取邮箱
    async sendEmailCode() {
      let data = {
        email: this.EmailForm.email,
      };
      this.$refs.EmailForm.validateField("email", async (valid) => {
        if (!valid) {
          const response = await getEmailCode(this.EmailForm.email);
          if (response.code === 200) {
            this.$message.success("发送成功");
            this.phoneCodeLogin60sEmail(); // 调用验证码倒计时
          } else {
            this.$message.warning(response.message);
          }
        }
      });
    },

    // 邮箱验证码
    phoneCodeLogin60sEmail() {
      // 验证码登录-获取验证码-60s倒计时
      this.phoneCodeLoginDisabledEmail = true;
      let count = 120;
      this.timer3 = setInterval(() => {
        count--;
        this.phoneCodeLoginTextEmail = count + "s";
        if (count === 0) {
          clearInterval(this.timer2);
          this.phoneCodeLoginTextEmail = "重新获取";
          this.phoneCodeLoginDisabledEmail = false;
        }
      }, 1000);
    },

    EmailOk() {
      this.$refs.EmailForm.validate(async (valid) => {
        if (valid) {
          let data = {
            code: this.EmailForm.code,
            email: this.EmailForm.email,
          };
          updateEmail(this.EmailForm.code, this.EmailForm.email).then((res) => {
            if (res.code == 200) {
              this.$message.success("设置成功");
              this.$refs.EmailForm.resetFields();
              this.load();
              this.EmailWord = false;
              this.phoneCodeLoginTextQ = "获取验证码";
            } else {
              this.$message.success("设置失败");
              this.$refs.EmailForm.resetFields();
            }
          });
        }
      });
    },
    EmailCancel() {
      this.EmailWord = false;
      this.$refs.EmailForm.resetFields();
      this.EmailForm = {};
      this.phoneCodeLoginTextQ = "获取验证码";
    },
    // getType() {
    //   if (JSON.parse(localStorage.getItem("userJson"))) {
    //     this.temp = JSON.parse(localStorage.getItem("userJson"));
    //   } else {
    //     this.temp.type = "";
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
.box {
  padding: 20px 20px;
  width: 100%;
  display: block;
  .item {
    display: block;
    width: 100%;
    height: 90px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .title {
      font-size: 16px;
      font-weight: 550;
      color: black;
    }
    .operation {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
