<template>
<div>
<!--  发票信息-->
<div class="table">
  <a-table :columns="invoice" :data-source="invoiceData" :pagination="false" :scroll="{ x: 2000 }">
    <a slot="action" slot-scope="text,record" @click="invoiceEdit(record)">修改</a>
    <span slot="name" slot-scope="text,record">
      <span v-if="memberStatus === 2">
        <!--      企业-->
        {{text}}</span>
<!--        <a-tag color="red">企业</a-tag>-->
      <!--      个人-->
      <span v-if="memberStatus === 1">
        {{text}}
<!--        <a-tag color="purple">个人</a-tag>-->
      </span>
    </span>
    <span slot="InvoiceType" slot-scope="text,record">{{record.invoiceType ? record.invoiceType :'无'}}</span>
    <span slot="taxNumber" slot-scope="text,record">{{text?text:'-'}}</span>
    <span slot="bank" slot-scope="text,record">{{text?text:'-'}}</span>
    <span slot="bankAccount" slot-scope="text,record">{{text?text:'-'}}</span>
    <span slot="address" slot-scope="text,record">{{text?text:'-'}}</span>
    <span slot="phone" slot-scope="text,record">{{text?text:'-'}}</span>
  </a-table>
</div>
  <!-- 修改发票信息 -->
  <a-modal
      width="700px"
      title="修改发票信息"
      :visible="invoiceInfoVisible"
      @ok="invoiceInfoOk"
      @cancel="invoiceInfoCancel"
  >
    <!--    memberStatus  0未认证 1个人认证 2企业认证-->
<!--    <div v-if="memberStatus === 2">-->
      <a-form-model
          :model="invoiceInfoForm"
          :rules="invoiceInfoRuleform"
          ref="invoiceInfoFormRef"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item label="发票类型" prop="invoiceType">
          <a-radio-group v-model="invoiceInfoForm.invoiceType" @change="selectInvoice">
            <a-radio value="增值税普通发票">
              增值税普通发票
            </a-radio>
            <a-radio value="增值税专用发票">
              增值税专用发票
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="发票抬头" prop="name">
          <a-input
              :disabled="this.memberStatus === 2"
              v-model="invoiceInfoForm.name"
              placeholder="请输入"
          ></a-input>
          <a-popover placement="top" v-if="memberStatus === 2">
            <template slot="content">
              <div style="width: 300px">发票抬头须与账号实名认证主体一致，若您要修改发票抬头需先<a @click="openRealNameAuthentication(infoList,memberStatus === 2 ?'DW':'')">修改实名认证信息</a>。</div>
            </template>
            <span style="color: #afafaf">如何修改专票抬头</span>
          </a-popover>
        </a-form-model-item>
        <a-form-model-item label="纳税人识别号" prop="taxNumber">
          <a-input
              v-model="invoiceInfoForm.taxNumber"
              placeholder="开企业抬头发票必填"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="基本开户银行" prop="bank">
          <a-input
              v-model="invoiceInfoForm.bank"
              placeholder="请输入"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="基本银行账号" prop="bankAccount">
          <a-input
              v-model="invoiceInfoForm.bankAccount"
              placeholder="请输入"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label=" 公司注册地址" prop="address">
          <a-input
              v-model="invoiceInfoForm.address"
              placeholder="填写街道地址，不必重复省市信息"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label=" 公司注册电话" prop="phone">
          <a-input v-model="invoiceInfoForm.phone" placeholder="请输入"></a-input>
        </a-form-model-item>
      </a-form-model>
<!--    </div>-->
<!--    <div v-if="memberStatus === 1">-->
<!--      <a-alert style="margin-bottom: 20px" message="请您与贵司财务人员核实发票信息，确保信息填写正确，以免影响发票的后续使用。" type="info" show-icon />-->
<!--      <a-form-model-->
<!--          :model="invoiceInfoFormG"-->
<!--          :rules="invoiceInfoRuleformG"-->
<!--          ref="invoiceInfoFormRefG"-->
<!--          :label-col="{ span: 5 }"-->
<!--          :wrapper-col="{ span: 17 }"-->
<!--      >-->
<!--        <a-form-model-item label="发票类型" prop="invoiceType">-->
<!--          <a-radio-group v-model="invoiceInfoFormG.invoiceType">-->
<!--            <a-radio value="增值税普通发票">-->
<!--              增值税普通发票-->
<!--            </a-radio>-->
<!--            <a-radio value="增值税专用发票" :disabled="true">-->
<!--              增值税专用发票-->
<!--            </a-radio>-->
<!--          </a-radio-group>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="发票抬头" prop="name">-->
<!--          <a-input-->
<!--              :disabled="true"-->
<!--              v-model="invoiceInfoFormG.name"-->
<!--              placeholder="填写街道地址，不必重复省市信息"-->
<!--          ></a-input>-->
<!--          <a-popover placement="top">-->
<!--            <template slot="content">-->
<!--              <div style="width: 300px">发票抬头须与账号实名认证主体一致，若您要修改发票抬头需先<a>修改实名认证信息</a>。</div>-->
<!--            </template>-->
<!--            <span style="color: #afafaf">如何修改专票抬头</span>-->
<!--          </a-popover>-->
<!--        </a-form-model-item>-->
<!--      </a-form-model>-->
<!--    </div>-->
  </a-modal>
  <!--认证-->
  <RealNameAuthentication
      :visible="visible"
      :jurisdiction="false"
      :isShow="isShow"
      v-on:closeMainRealNameAuth="closeMain"
      v-on:checkList="checkList"
  ></RealNameAuthentication>
</div>
</template>

<script>
import {dutyParagraphIsRepeat, getAuthenticationInformation, invoiceHeader, updatebyid} from "@/service/authentication";
import RealNameAuthentication from "@/components/Authentication/RealNameAuthentication";
const invoice = [
  {
    title: '发票抬头',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },{
    title: '发票类型',
    dataIndex: 'invoiceType',
    key: 'invoiceType',
    scopedSlots: { customRender: 'InvoiceType' },
  },{
    title: '纳税人识别号',
    dataIndex: 'taxNumber',
    key: 'taxNumber',
    scopedSlots: { customRender: 'taxNumber' },
  },{
    title: '基本开户银行',
    dataIndex: 'bank',
    key: 'bank',
    scopedSlots: { customRender: 'bank' },
  },{
    title: '基本开户账号',
    dataIndex: 'bankAccount',
    key: 'bankAccount',
    scopedSlots: { customRender: 'bankAccount' },
  },{
    title: '公司注册地址',
    dataIndex: 'address',
    key: 'address',
    width:'300px',
    scopedSlots: { customRender: 'address' },
  },{
    title: '公司注册电话',
    dataIndex: 'phone',
    key: 'phone',
    scopedSlots: { customRender: 'phone' },
  },{
    title: '操作',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
];
const invoiceData = [];
export default {
name: "InvoiceInformation",
  components:{RealNameAuthentication},
  data(){
    let validatePass = async (rule, value, callback) => {
      const reg = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/;
      if (!value) {
        return callback(); //取消报错
      } else {
        if (!reg.test(value)) { //格式错误
          return callback(new Error('请输入正确的统一社会信用代码'));
        }
        else { //格式正确，判断是否重复
          let data = {
            type:'taxNumber', // 类型 isCard:身份证号码  taxNumber:信用码
            taxNumber:value
          }
          const response = await dutyParagraphIsRepeat(data)
          if(response.code === 0) {
            if (response.data) { //没重复
              return callback(); //取消报错
            } else { //重复
              return callback(new Error('统一社会信用代码已存在，请重新输入'));
            }
          }
        }
      }
    };
    return{
      invoice,
      invoiceData,
      invoiceInfoVisible:false,
      memberStatus:null,
      invoiceInfoForm:{
        invoiceType:'',
        name:'',
        taxNumber:'',
        address:'',
        bank:'',
        bankAccount:'',
        phone:'',
      },
      invoiceInfoRuleform:{},
      invoiceInfoRuleformZ:{ //专业发票
        invoiceType: [{ required: true, message: "请输入", trigger: "change" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        taxNumber: [{ required: true, validator: validatePass, trigger: "blur" }],
        address: [{ required: true, message: "请输入", trigger: "blur" }],
        bank: [{ required: true, message: "请输入", trigger: "blur" }],
        bankAccount: [{ required: true, message: "请输入", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入", trigger: "blur" },
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "请输入正确的手机号码",
          //   trigger: "blur",
          // },
        ],
      },
      invoiceInfoRuleformP:{ //普通发票
        invoiceType: [{ required: true, message: "请输入", trigger: "change" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        taxNumber: [{ required: true, validator: validatePass, trigger: "blur" }],
      },
      invoiceInfoFormG:{
        invoiceType:'',
        name:'',
      },
      invoiceInfoRuleformG:{
        invoiceType: [{ required: true, message: "请输入", trigger: "change" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      userId:null,
      isShow: {},
      visible:false,
    }
  },
  props:{
    // 接收父组件传递的参数
    infoList: {
      type: Object,
    },
  },
  created() {
    this.getInformation();
    const user = JSON.parse(localStorage.getItem("yichi_userInfo"));
    this.userId = user.id;
    // this.memberStatus = user.memberStatus; //身份 个人/企业
    this.getInvoiceHeader();
  },
  methods:{
    //获取个人信息
    async getInformation() {
      const response = await getAuthenticationInformation();
      if (response.code === 200) {
        this.memberStatus = response.data.memberStatus; //身份 个人/企业
      } else {
        this.$message.warning("name", response.message);
      }
    },
    closeMain(val) {
      this.visible = val;
    },
    checkList(val) {
      this.getInvoiceHeader();
    },
    openRealNameAuthentication(row, type) {
      if (type === "DW") {
        //判断当type为DW时，为企业，自动切换到企业认证界面
        this.$set(row, "companyStatusDW", 2);
      } else {
        this.$set(row, "companyStatusDW", row.memberStatus);
      }
      this.isShow = row;
      this.visible = true;
      this.invoiceInfoVisible = false;
    },
    selectInvoice(){ //选择发票类型;
      if(this.memberStatus === 2){ //企业
        if(this.invoiceInfoForm.invoiceType === '增值税普通发票'){
          this.invoiceInfoRuleform = this.invoiceInfoRuleformP;
        }else if(this.invoiceInfoForm.invoiceType === '增值税专用发票'){
          this.invoiceInfoRuleform = this.invoiceInfoRuleformZ;
        }
      }else if(this.memberStatus === 1){//个人
        if(this.invoiceInfoForm.invoiceType === '增值税普通发票'){
          this.invoiceInfoRuleform = this.invoiceInfoRuleformG;
        }else if(this.invoiceInfoForm.invoiceType === '增值税专用发票'){
          this.invoiceInfoRuleform = this.invoiceInfoRuleformZ;
        }
      }
    },
    invoiceInfoCancel(){
      this.invoiceInfoVisible = false;
      this.$refs.invoiceInfoFormRef.resetFields(); //清空表单
    },
    //获取发票抬头信息
    async getInvoiceHeader() {
      const response = await invoiceHeader();
      if (response.code === 0) {
        this.invoiceData = [response.data];
        // this.invoiceList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    //修改发票信息
    async updateOrderInfo(data) {
      const response = await updatebyid(data);
      if (response.code === 0) {
        this.invoiceInfoVisible = false;
        this.$refs.invoiceInfoFormRef.resetFields(); //清空表单
        this.invoiceInfoForm = {};
        // if(this.memberStatus === 2){
        //   this.$refs.invoiceInfoFormRef.resetFields(); //清空表单
        //   this.invoiceInfoForm = {};
        // }else if(this.memberStatus === 1){
        //   this.$refs.invoiceInfoFormRefG.resetFields(); //清空表单
        //   this.invoiceInfoFormG = {};
        // }
        //刷新发票抬头信息
        await this.getInvoiceHeader();
      } else {
        this.$message.warning(response.message);
      }
    },
    invoiceInfoOk(){
      let data = {};
      //判断是企业还是个人
      // if(this.memberStatus === 2){ //企业
        this.$refs.invoiceInfoFormRef.validate((valid) => {
          if (valid) {
            let form = this.invoiceInfoForm;
            data = {
              "id": form.id,//必填，其他选填
              "name": form.name,//公司名称  发票抬头
              "taxNumber": form.taxNumber,//纳税人识别号
              "address": form.address,//公司注册地址
              "phone": form.phone,//公司注册电话
              "bank": form.bank,//基本开户银行
              "bankAccount": form.bankAccount,//基本开户账号
              "invoiceType":form.invoiceType //发票类型
            }
            this.updateOrderInfo(data);
          }
        });
      // }else if(this.memberStatus === 1){ //个人
      //   //个人只能查看信息，无法修改
      //   this.invoiceInfoVisible = false;
      // }
    },
    //修改发票抬头
    invoiceEdit(record){
      //判断是企业还是个人
      if(this.memberStatus === 2){ //企业
        this.invoiceInfoForm = {
          id:record.id,
          invoiceType:record.invoiceType,
          name:record.name,
          taxNumber:record.taxNumber,
          address:record.address,
          bank:record.bank,
          bankAccount:record.bankAccount,
          phone:record.phone,
        }
        if(!record.invoiceType){  //发票类型为空时，默认普票
          this.invoiceInfoForm.invoiceType = '增值税普通发票';
          this.invoiceInfoRuleform = this.invoiceInfoRuleformP;
        }else {
          if(record.invoiceType === '增值税专用发票'){
            this.invoiceInfoRuleform = this.invoiceInfoRuleformZ;
          }else if(record.invoiceType === '增值税普通发票'){
            this.invoiceInfoRuleform = this.invoiceInfoRuleformP;
          }
        }
        this.invoiceInfoVisible = true;
      }else if(this.memberStatus === 1){ //个人
        // this.invoiceInfoFormG = {
        //   invoiceType:record.invoiceType,
        //   name:record.name,
        // }
        // this.invoiceInfoFormG.invoiceType = '增值税普通发票';
        // this.invoiceInfoRuleform = this.invoiceInfoRuleformP;
        //
        // this.invoiceInfoVisible = true;

        this.invoiceInfoForm = {
          id:record.id,
          invoiceType:record.invoiceType,
          name:record.name ? record.name : '个人',

          taxNumber:record.taxNumber,
          address:record.address,
          bank:record.bank,
          bankAccount:record.bankAccount,
          phone:record.phone,
        }

        if(!record.invoiceType){  //发票类型为空时，默认普票
          this.invoiceInfoForm.invoiceType = '增值税普通发票';
          this.invoiceInfoRuleform = this.invoiceInfoRuleformG;
        }else {
          //个人 -- 专票
          if (record.invoiceType === '增值税专用发票') {
            this.invoiceInfoRuleform = this.invoiceInfoRuleformZ;
          } else if (record.invoiceType === '增值税普通发票') {
            //个人 -- 普票
            this.invoiceInfoRuleform = this.invoiceInfoRuleformG;
          }
        }
        // this.invoiceInfoForm.invoiceType = '增值税普通发票';
        // this.invoiceInfoRuleform = this.invoiceInfoRuleformP;

        this.invoiceInfoVisible = true;
      }else if(this.memberStatus === 0){
        this.$message.warning("未认证或者认证未通过！")
      }
    },
  }
}
</script>

<style scoped lang="scss">
.table{
  .rpa-table-header{
    overflow: hidden !important;;
    margin-bottom:auto!important;
  }
  ::-webkit-scrollbar {
    width:10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb{
    background-color: #C1C1C1;
  }
  ::-webkit-scrollbar-track {
    background-color: #F1F1F1;
  }
}
</style>
