<template>
  <!-- 邮寄地址 -->
  <div class="address">
    <div><a-button type="primary" @click="add">+添加邮寄地址</a-button></div>
    <a-table
      :columns="columns"
      style="min-height: 500px; margin-top: 30px"
      :data-source="dataSource"
      :pagination="false"
      rowKey="id"
      class="components-table-demo-nested"
    >
      <span slot="address" slot-scope="text, record, index">
        <span
          >{{ record.addressProvince }}{{ record.addressCity
          }}{{ record.addressDetail }}</span
        >
      </span>
      <span slot="operation" slot-scope="text, record, index">
        <a-tag v-if="record.isDefault == 1" color="#108ee9">默认地址</a-tag>
        <a
          v-if="record.isDefault == 0"
          @click="setDefault(record.id)"
          style="margin-right: 15px"
          >设置默认</a
        >
        &ensp;&ensp;
        <a @click="edit(record)">修改</a>
        &ensp;&ensp;
        <a-popconfirm
          title="确认删除?"
          ok-text="确认"
          cancel-text="取消"
          @confirm="confirm(record.id)"
          @cancel="cancel"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <!-- 新增弹窗 -->
    <a-modal
      title="新增邮寄地址"
      :visible="addShow"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        :model="form"
        :rules="ruleform"
        ref="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item label="收件人姓名" prop="name">
          <a-input v-model="form.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="邮寄地址"
          prop="address"
          placeholder="请选择省市"
        >
          <a-cascader
            allowClear
            v-model="form.address"
            :options="options"
            placeholder="请选择省市"
            @change="provinceChange"
          />
        </a-form-model-item>
        <a-form-model-item label="详细地址" prop="addressDetail">
          <a-input
            v-model="form.addressDetail"
            placeholder="填写街道地址，不必重复省市信息"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系方式" prop="phone">
          <a-input v-model="form.phone" placeholder="请输入"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 修改弹窗 -->

    <a-modal
      title="修改邮寄地址"
      :visible="editShow"
      @ok="editOk"
      @cancel="editCancel"
    >
      <a-form-model
        :model="editform"
        :rules="ruleform"
        ref="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item label="收件人姓名" prop="name">
          <a-input v-model="editform.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="邮寄地址"
          prop="address"
          placeholder="请选择省市"
        >
          <a-cascader
            allowClear
            :value="editform.address"
            :options="options"
            placeholder="请选择省市"
            @change="editprovince"
          />
        </a-form-model-item>
        <a-form-model-item label="详细地址" prop="addressDetail">
          <a-input
            v-model="editform.addressDetail"
            placeholder="填写街道地址，不必重复省市信息"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系方式" prop="phone">
          <a-input v-model="editform.phone" placeholder="请输入"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getAddressList,
  mailingAddress,
  delAddress,
  newAddress,
  editAddress,
} from "@/service/columnmanageapi";

import City from "@/assets/city.json";

export default {
  name: "addressView",
  data() {
    return {
      City: City,
      userId: "",
      dataSource: [],
      addShow: false,
      form: {},
      editform: {},
      editShow: false,
      columns: [
        {
          title: "收件人",
          dataIndex: "name",
          width: "13%",
        },
        {
          title: "联系方式",
          dataIndex: "phone",
          width: "17%",
        },
        {
          title: "收件地址",
          dataIndex: "address",
          width: "40%",
          scopedSlots: { customRender: "address" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "30%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      ruleform: {
        name: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (value.trim().length === 0) {
                callback(new Error("请输入正确的内容"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        address: [{ required: true, message: "请选择", trigger: "change" }],
        addressDetail: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (value.trim().length === 0) {
                callback(new Error("请输入正确的地址"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
      options: [],
    };
  },
  created() {
    const user = JSON.parse(localStorage.getItem("yichi_userInfo"));
    this.userId = user.id;
    this.load();
    this.getPCH();
  },
  methods: {
    // 获取地址
    async load() {
      const res = await getAddressList(this.userId);
      if (res.code === 0) {
        this.dataSource = res.data;
      }
    },
    // 设置为默认
    async setDefault(id) {
      const res = await mailingAddress(id);
      if (res.code == 0) {
        this.$message.success("设置默认邮寄地址成功");
        await this.load();
      } else {
        this.$message.warning(res.message);
      }
    },
    // 打开新增弹窗
    add() {
      this.addShow = true;
    },
    //  删除邮寄地址
    async confirm(id) {
      const res = await delAddress(id);
      if (res.code == 0) {
        this.$message.success("删除成功");
        await this.load();
      } else {
        this.$message.warning(res.message);
      }
    },
    handleCancel() {
      this.addShow = false;
      this.$refs.form.resetFields();
    },
    cancel() {},

    async handleOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let data = {
            name: this.form.name,
            phone: this.form.phone,
            addressProvince: this.form.address[0],
            addressCity: this.form.address[1],
            addressDetail: this.form.addressDetail,
            isDefault: 0,
          };
          console.log(data);
          const res = await newAddress(data);
          if (res.code === 0) {
            this.$message.success("添加成功");
            this.addShow = false;
            this.form = {};
            await this.load();
          } else {
            this.$message.warning(res.message);
          }
        }
      });
    },

    provinceChange(value) {
      this.form.address = value;
    },
    editprovince(value) {
      this.editform.address[0] = value[0];
      this.editform.address[1] = value[1];
    },

    // 修改地址
    edit(record) {
      this.load();
      // this.editform.id = record.id;
      // this.editform.name = record.name;
      // this.editform.phone = record.phone;
      // this.editform.addressDetail = record.addressDetail;
      this.editform = record;
      this.editform.address = [record.addressProvince, record.addressCity];
      this.editShow = true;
    },

    editCancel() {
      this.editShow = false;
      this.$refs.form.resetFields();
    },
    async editOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let data = {
            id: this.editform.id,
            name: this.editform.name,
            phone: this.editform.phone,
            addressProvince: this.editform.address[0],
            addressCity: this.editform.address[1],
            addressDetail: this.editform.addressDetail,
          };
          const res = await editAddress(data);
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.editShow = false;
            this.editform = {};
            await this.load();
          } else {
            this.$message.warning(res.message);
          }
        }
      });
    },

    getPCH() {
      let province = [];
      this.City.forEach((item, index) => {
        let city = [];
        province.push({
          value: item.name,
          label: item.name,
          children: city,
        });
        item.cityList.forEach((list) => {
          city.push({
            value: list.name,
            label: list.name,
            loading: false,
          });
        });
        province[index].children = city;
      });
      this.options = province;
    },
  },
};
</script>

<style scoped lang="scss">
.address {
  width: 98%;
  margin: auto;
  margin-top: 2%;
}
</style>
