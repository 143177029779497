<template>
  <div class="accountManagement">
    <!--  认证管理-->
    <div class="accountManagement-left">
      <!--        身份选择-->
      <div>
        <a-menu
          style="width: 100%; border-right: none"
          v-model="selectId"
          mode="inline"
        >
          <a-menu-item
            v-for="(item, index) in templateList"
            @click="selectStatus(item.title)"
            :key="item.title"
          >
            {{ item.title }}
          </a-menu-item>
        </a-menu>
      </div>
    </div>

    <div v-if="title === '账号信息'">
      <div class="accountManagement-right">
        <!--    基本信息 --- 未认证 -->
        <div v-if="authenticationStatus === 0" class="sty">
          <div style="display: flex">
            <span class="info">基本信息</span>
            <a-alert type="warning" show-icon>
              <span slot="message">
                <span style="color: rgba(0, 0, 0, 0.65)"
                  >您的账号未完成实名认证，点击<span
                    ><a @click="openRealNameAuthentication(infoList)"
                      >立即认证</a
                    ></span
                  ></span
                >
              </span>
            </a-alert>
          </div>
          <div class="content">
            <div class="widthDiv">
              <span class="font">姓名：</span> {{ infoList.contactsName }}
            </div>
            <div class="widthDiv">
              <span class="font">手机号： </span>{{ infoList.contactsTel }}
            </div>
          </div>
          <div class="content">
            <div class="widthDiv">
              <span class="font">认证类型：</span>
              <!--          "companyStatus": 0,//认证状态 0-未提交认证 1已提交待审核 2已审核未通过 3已通过审核    -->
              <a
                @click="openRealNameAuthentication(infoList)"
                :style="{
                  color:
                    infoList.companyStatus === 0
                      ? ''
                      : infoList.companyStatus === 1
                      ? '#45a5e6'
                      : infoList.companyStatus === 2
                      ? 'red'
                      : '#35D57E',
                }"
              >
                {{
                  infoList.companyStatus === 0
                    ? "未认证"
                    : infoList.companyStatus === 1
                    ? "待审核"
                    : infoList.companyStatus === 2
                    ? "未通过"
                    : "已认证"
                }}
              </a>
            </div>
            <div class="widthDiv">
              <span class="font">注册时间：</span>{{ infoList.createdTime }}
            </div>
          </div>
          <div style="margin-top: 15px">
            <a-button
              :disabled="infoList.companyStatus !== 0"
              @click="openInfoModel(infoList)"
              >修改信息</a-button
            >
            <a-button
              @click="openRealNameAuthentication(infoList)"
              style="margin-left: 20px"
              >账号认证</a-button
            >
          </div>
        </div>
        <!--    基本信息 --- 个人认证 -->
        <div v-if="authenticationStatus === 1" class="sty">
          <span class="info">基本信息</span>
          <!-- "companyStatus": 0,//认证状态 0-未提交认证 1已提交待审核 2已审核未通过 3已通过审核    -->
          <a-tag
            :color="
              infoList.companyStatus === 0
                ? ''
                : infoList.companyStatus === 1
                ? 'blue'
                : infoList.companyStatus === 2
                ? 'red'
                : 'green'
            "
          >
            {{
              infoList.companyStatus === 0
                ? "未认证"
                : infoList.companyStatus === 1
                ? "认证待审核"
                : infoList.companyStatus === 2
                ? "认证未通过"
                : "已认证"
            }}
          </a-tag>
          <div class="content">
            <div class="widthDiv">
              <span class="font">姓名：</span> {{ infoList.contactsName }}
            </div>
            <div class="widthDiv">
              <span class="font">手机号： </span>{{ infoList.contactsTel }}
            </div>
            <div class="widthDiv">
              <span class="font">身份证号： </span>{{ infoList.contactsIdCard }}
            </div>
          </div>
          <div class="content">
            <div class="widthDiv">
              <span class="font">认证类型：</span>个人认证
            </div>
            <div class="widthDiv">
              <span class="font">认证完成时间：</span
              >{{ infoList.reviewedTime }}
            </div>
            <div class="widthDiv">
              <span class="font">注册时间：</span>{{ infoList.createdTime }}
            </div>
          </div>
          <div style="margin-top: 15px">
            <a-button
              :disabled="infoList.companyStatus !== 3"
              @click="openRealNameAuthentication(infoList,'','变更认证为单位')">修改认证信息</a-button
            >
            <a-button
              @click="openRealNameAuthentication(infoList, 'DW','变更认证为单位')"
              style="margin-left: 20px"
              >变更认证为单位</a-button
            >
          </div>
        </div>
        <!--    基本信息 --- 企业认证 -->
        <div v-if="authenticationStatus === 2" class="sty">
          <span class="info">基本信息</span>
          <!-- "companyStatus": 0,//认证状态 0-未提交认证 1已提交待审核 2已审核未通过 3已通过审核    -->
          <a-tag
            :color="
              infoList.companyStatus === 0
                ? ''
                : infoList.companyStatus === 1
                ? 'blue'
                : infoList.companyStatus === 2
                ? 'red'
                : 'green'
            "
          >
            {{
              infoList.companyStatus === 0
                ? "未认证"
                : infoList.companyStatus === 1
                ? "认证待审核"
                : infoList.companyStatus === 2
                ? "认证未通过"
                : "已认证"
            }}
          </a-tag>
          <div class="content">
            <div class="widthDiv">
              <span class="font">企业名称：</span>{{ infoList.companyName }}
            </div>
            <div class="widthDiv">
              <span class="font">统一社会信用代码： </span
              >{{ infoList.taxNumber }}
            </div>
            <div class="widthDiv">
              <span class="font">注册时间： </span>{{ infoList.createdTime }}
            </div>
          </div>
          <div class="content">
            <div class="widthDiv">
              <span class="font">认证类型：</span>企业认证
            </div>
            <div class="widthDiv">
              <span class="font">认证完成时间：</span
              >{{ infoList.reviewedTime }}
            </div>
          </div>
          <div class="line"></div>
          <span class="info">联系人信息</span>
          <div class="content">
            <div class="widthDiv">
              <span class="font">姓名：</span>{{ infoList.contactsName }}
            </div>
            <div class="widthDiv">
              <span class="font">手机号：</span>{{ infoList.contactsTel }}
            </div>
          </div>
<!--         isStaff  0子账号 1主账号 子账号不显示按钮 -->
          <div v-if="infoList.isStaff" style="margin-top: 15px">
             <!--            修改认证信息 --- 只能修改个人信息，企业信息无法修改-->
            <a-button @click="openRealNameAuthentication(infoList, 'DW')">修改认证信息</a-button>
            <!--             可以修改所有认证信息  -->
            <a-button style="margin-left: 20px" @click="openRealNameAuthentication(infoList, 'DW','变更认证为单位')">变更认证为单位</a-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 安全设置 -->
    <div v-if="title === '安全设置'" class="accountManagement-right2">
      <div></div>
      <safeView></safeView>
    </div>
    <!-- 邮寄地址 -->
    <div v-if="title === '邮寄地址'" class="accountManagement-right2">
      <div></div>
      <addressView></addressView>
    </div>
    <!--  子账号管理    -->
    <div v-if="title === '子账号管理'" class="accountManagement-right2">
      <ChildAccountManage v-if="title === '子账号管理'"/>
    </div>

<!--    <div v-if="title === '发票信息'" class="accountManagement-right2">-->
<!--      <div></div>-->
<!--      <InvoiceInformation :infoList="infoList"></InvoiceInformation>-->
<!--    </div>-->
    <!--  对话框  -->
    <a-modal
      v-model="infoVisible"
      title="个人信息"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="姓名" prop="name">
          <a-input class="input" v-model="form.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="tel">
          <a-input class="input" v-model="form.tel" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="code">
          <a-input class="input" v-model="form.code" placeholder="请输入验证码">
            <a-tooltip slot="suffix">
              <a
                type="primary"
                @click="phoneCodeLogin"
                :disabled="phoneCodeLoginDisabled"
                >{{ phoneCodeLoginText }}</a
              >
            </a-tooltip>
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!--认证-->
    <RealNameAuthentication
      :visible="visible"
      :jurisdiction="jurisdiction"
      :isShow="isShow"
      v-on:closeMainRealNameAuth="closeMain"
      v-on:checkList="checkList"
    ></RealNameAuthentication>
<!--    未开发票消息提示弹窗-->
    <MakeOutInvoice
        :visible="MakeOutInvoiceVisible"
        :money="money"
        :userInfoList="userInfoList"
        v-on:closeMain="MakeOutInvoiceCloseMain"
        v-on:checkList="MakeOutInvoiceCheckList"
    ></MakeOutInvoice>
  </div>
</template>

<script>
import { postLoginPhoneCode } from "@/service/common";
import RealNameAuthentication from "@/components/Authentication/RealNameAuthentication";
import safeView from "@/components/Material/safeView";
import addressView from "@/components/Material/addressView";
import {
  delOrder,
  getAuthenticationInformation, getOrder,
  getSubmitCertification,
  sendSMS,
  updAuthenticationInFo,
} from "@/service/authentication";
import InvoiceInformation from "@/components/Material/InvoiceInformation";
import MakeOutInvoice from "@/components/Authentication/MakeOutInvoice";
import ChildAccountManage from "@/views/content/account/childAccountManage";

export default {
  name: "AccountManagement",
  components: {
    ChildAccountManage,
    MakeOutInvoice,
    InvoiceInformation,
    RealNameAuthentication,
    safeView,
    addressView,
  },

  data() {
    return {
      title: "账号信息",
      authenticationStatus: 0, //"memberStatus": 0,//账号类型 0未认证 1个人认证 2企业认证
      selectId: ["账号信息"],
      templateList: [
        {
          title: "账号信息",
          id: 1,
        },
        {
          title: "安全设置",
          id: 2,
        },
        {
          title: "子账号管理",
          id: 5,
        },
        // {
        //   title: "发票信息",
        //   id: 3,
        // },
        // {
        //   title: "邮寄地址",
        //   id: 4,
        // },
      ],
      infoVisible: false,

      form: {
        name: "",
        tel: "",
        code: "",
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        tel: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      phoneCodeLoginDisabled: false,
      phoneCodeLoginText: "获取验证码",
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      visible: false,
      jurisdiction:true,
      infoList: {},
      isShow: {},
      MakeOutInvoiceVisible: false,
      userInfoList:{},
      money:0,
    };
  },
  created() {
    this.getInformation();
  },
  methods: {
    //获取基本信息
    async getInformation() {
      const response = await getAuthenticationInformation();
      if (response.code === 200) {
        this.authenticationStatus = response.data.memberStatus;
        this.infoList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    openRealNameAuthentication(row, type,jurisdiction) {
      // console.log(row)
      this.isShow = row;
      if (type === "DW") {
        if(jurisdiction === '变更认证为单位'){ //满足DW和jurisdiction为变更认证为单位时，获取未开发票的金额，有未开发票金额弹窗提示
          this.jurisdiction = false; //可以修改
          //判断当前是否为审批状态，若为审批状态，直接弹窗认证
          if(row.companyStatus === 1){ //待审核
            //判断当type为DW时，为企业，自动切换到企业认证界面
            this.$set(row, "companyStatusDW", 2);
            this.visible = true;
          }else {
            //判断弹窗，获取未开发票订单金额判断弹窗则显示提示信息
            this.uninvoicedOrder(row);
          }
        }else {
          this.jurisdiction = true;
          //判断当type为DW时，为企业，自动切换到企业认证界面
          this.$set(row, "companyStatusDW", 2);
          this.visible = true;
        }

      } else {
        this.$set(row, "companyStatusDW", row.memberStatus);
        this.visible = true;
      }

    },
    //获取未开发票订单信息
    async uninvoicedOrder(row) {
      this.spinning = true;
      let data = {
        page_no:this.page_no,
        page_size:999999
      }
      const response = await getOrder(data);
      if (response.code === 0) {
        let num = 0;
        for (let i=0;i<response.data.length;i++){
          // arr.push(response.data[i]);
          // this.$set(response.data[i],'orderTable',arr);
          if(response.data[i].orderStatus === 4 && response.data[i].isInvoicing === 0){ //判断未申请开票的金额
            num = num + response.data[i].orderMoney;
          }
        }
        if(num){
          //打开消息提示弹窗
          this.MakeOutInvoiceVisible = true;
          this.money = num;
        }else {
          //判断当type为DW时，为企业，自动切换到企业认证界面
          this.$set(row, "companyStatusDW", 2);
          this.visible = true;
        }
      }else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    closeMain(val) {
      this.visible = val;
    },
    checkList(val) {
      this.getInformation();
    },
    MakeOutInvoiceCloseMain(val) {
      this.MakeOutInvoiceVisible = val;
    },
    MakeOutInvoiceCheckList(val) {
      // console.log(val)
      let isOpen = val; //true 继续变更 false 开具发票
      if(isOpen){ //继续变更
        //判断当type为DW时，为企业，自动切换到企业认证界面
        this.$set(this.isShow, "companyStatusDW", 2);
        this.visible = true;
      }else { //开具发票
        this.$router.push({path:'/orderManagement'})
      }
    },
    selectStatus(title) {
      this.title = title;
      console.log(this.title);
    },
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let data = {
            name: this.form.name,
            tel: this.form.tel,
            password: this.form.code, //验证码
          };
          const response = await updAuthenticationInFo(data);
          if (response.code === 200) {
            await this.getInformation();
            this.$refs.ruleForm.resetFields();
            this.infoVisible = false;
          } else {
            this.$message.warning(response.message);
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.infoVisible = false;
    },
    openInfoModel(list) {
      this.form = {
        name: list.contactsName,
        tel: list.contactsTel,
      };
      this.infoVisible = true;
    },
    async phoneCodeLogin() {
      //验证码登录-获取验证码
      this.$refs.ruleForm.validateField("tel", async (valid) => {
        if (!valid) {
          //不为空
          let data = {
            tel: this.form.tel,
          };
          const response = await sendSMS(data);
          if (response.code === 0) {
            this.$message.success("发送成功");
            this.phoneCodeLogin60s(); // 调用验证码倒计时
          } else {
            this.$message.warning(response.message);
          }
        }
      });
    },
    phoneCodeLogin60s() {
      // 验证码登录-获取验证码-60s倒计时
      this.phoneCodeLoginDisabled = true;
      let count = 60;
      let interval = setInterval(() => {
        count--;
        this.phoneCodeLoginText = count + "s";

        if (count === 0) {
          clearInterval(interval);
          this.phoneCodeLoginText = "重新获取";
          this.phoneCodeLoginDisabled = false;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.accountManagement {
  border: 10px solid #edeff2;
  display: flex;
  .accountManagement-left {
    width: 13%;
  }
  .accountManagement-right {
    min-height: 850px;
    border-left: 1px solid #edeff2;

    .sty {
      margin: 10px 20px;
    }
    .line {
      margin: 25px 0 20px 0;
      border-top: 1px solid #edeff2;
      width: 100%;
    }
    .info {
      margin-top: 10px;
      margin-right: 10px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
      font-size: 16px;
    }
    .content {
      display: flex;
      //justify-content: space-between;
      margin-top: 15px;
      //margin-bottom: 15px;
      .widthDiv {
        width: 300px;
      }
      .font {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .accountManagement-right2 {
    min-height: 850px;
    border-left: 1px solid #edeff2;
    width: 87%;
  }
}

.input {
  width: 382px;
}
.btnSty {
  width: 382px;
  background: linear-gradient(to right, #6fb1ff, #095fff);
  border: none;
}
</style>
