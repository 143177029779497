<template>
  <div class="box">
<!--    <div class="liebiao">-->
<!--      <div class="title">子账号列表</div>-->
<!--    </div>-->
    <div class="btn">
      <a-button type="primary" @click="addChildAccount">新建子账号</a-button>
    </div>
    <div class="table">
      <a-table
        :columns="columns"
        style="min-height: 500px"
        :data-source="dataSource"
        :pagination="false"
        class="components-table-demo-nested"
      >
        <span slot="index" slot-scope="text, record, index">{{
          index + 1
        }}</span>
        <span slot="isStaff" slot-scope="text, record, index">
          <a-tag v-if="record.isStaff === 1" color="blue">主账号</a-tag>
          <a-tag v-else color="cyan">子账号</a-tag>
        </span>
        <span slot="operation" slot-scope="text, record, index">
          <a v-if="record.isStaff !== 1" @click="move(index, record)">移除</a>
          <a-popover v-if="previewIndex == index" v-model="popoverShow">
            <template slot="content">
              <div
                style="
                  padding: 10px 10px 0px 10px;
                  border-bottom: 1px solid #e9e9e9;
                "
              >
                <p>确定移除此子账号？</p>
              </div>
              <div style="padding: 10px 10px 10px 10px">
                <a-button size="small" @click="delCancel">取消</a-button>
                &ensp;
                <a-button size="small" type="primary" @click="delOk(record.id)"
                  >确定</a-button
                >
              </div>
            </template>
          </a-popover>
        </span>
      </a-table>
      <div style="width: 100%;" v-show="dataSource.length">
        <div style="display: flex;justify-content: center">
          <a-pagination
              show-quick-jumper
              :current="page_no"
              :pageSize="pageSize"
              :total="total"
              @change="paginationChange" />
        </div>
        <div style="height: 10px"></div>
      </div>
    </div>

    <!-- 新增弹窗 -->
    <a-modal
      title="新增"
      :visible="addShow"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        :rules="rule"
        :model="form"
        ref="formAdd"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item label="登录手机号" prop="tel">
          <a-input placeholder="请输入" v-model="form.tel"></a-input>
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="sms">
          <a-input
            class="sign-up-input"
            placeholder="请输入验证码"
            v-model="form.sms"
          >
            <a-tooltip slot="suffix">
              <a
                size="small"
                type="primary"
                @click="phoneCodeLoginQ"
                :disabled="phoneCodeLoginDisabledQ"
                >{{ phoneCodeLoginTextQ }}</a
              >
            </a-tooltip>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="name">
          <a-input placeholder="请输入" v-model="form.name"></a-input>
        </a-form-model-item>
        <p
          style="margin-left: 70px; color: red; font-size: 13px"
          v-show="beizu1"
        >
          注：该手机号已是平台认证会员，无法成为子账号，请更换未认证手机号
        </p>
        <p
          style="margin-left: 70px; color: red; font-size: 13px"
          v-show="beizu2"
        >
          注：该手机号已注册平台会员，请选择 <a @click="next">继续绑定</a> 或
          <a @click="change">更换手机号</a>
        </p>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getChildAccount,
  addChild,
  getSMS,
  delChild,
} from "@/service/columnmanageapi";
export default {
  name: "childAccountManage",
  data() {
    return {
      total:0,
      page_no:1,
      pageSize:5,
      dataSource: [],
      companyId: "",
      addShow: false,
      phoneCodeLoginDisabledQ: false,
      phoneCodeLoginTextQ: "获取验证码",
      form: {
        tel: "",
      },
      timer: {},
      beizu1: false,
      beizu2: false,
      popoverShow: false,
      previewIndex: "",
      columns: [
        {
          title: "序号",
          width: "9%",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "姓名",
          dataIndex: "name",
          width: "20%",
        },
        {
          title: "登录手机号",
          dataIndex: "tel",
          width: "20%",
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          width: "15%",
        },

        {
          title: "类型",
          width: "14%",
          scopedSlots: { customRender: "isStaff" },
        },
        {
          title: "操作",
          width: "14%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      rule: {
        tel: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        sms: [{ required: true, message: "请输入", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  created() {
    const company = JSON.parse(localStorage.getItem("yichi_userInfo"));
    this.companyId = company.companyId;
    this.isStaff = company.isStaff;
    this.load();
  },
  methods: {
    async load() {
      let data = {
        page:this.page_no,
        pageSize:5
      }
      const res = await getChildAccount(this.companyId,data);
      if (res.code == 0) {
        this.dataSource = res.data;
        this.total = res.count;
      }
    },
    paginationChange(page_no,page_size){
      this.page_no = page_no;
      this.load();
    },
    //打开新建弹窗
    addChildAccount() {
      if (this.isStaff == 1) {
        this.addShow = true;
      } else {
        this.$message.warning("此操作需要主账号进行");
      }
    },
    // 验证码倒计时
    async phoneCodeLoginQ() {
      this.$refs.formAdd.validateField("tel", async (valid) => {
        if (!valid) {
          //获取验证码
          let data = {
            tel: this.form.tel,
          };
          const response = await getSMS(data);
          if (response.code === 0) {
            this.$message.success("发送成功");
            await this.phoneCodeLogin60sQ(); // 调用验证码倒计时
          } else {
            if (
              response.message == "该手机账号已经认证为个人，不可添加为子账号"
            ) {
              this.beizu1 = true;
              this.$message.warning(
                "该手机账号已经认证为个人，不可添加为子账号"
              );
            } else if (
              response.message ==
              "给手机号已经注册未认证，请您再次确认是否继续认证"
            ) {
              this.beizu2 = true;
              this.$message.warning(
                "该手机号已经注册未认证，请您再次确认是否继续认证"
              );
            } else {
              this.$message.warning(response.message);
            }
          }
        }
      });
    },
    // 继续绑定
    async next() {
      this.beizu2 = false;
      let data = {
        tel: this.form.tel,
        confirm: 1,
      };
      const response = await getSMS(data);
      if (response.code === 0) {
        this.$message.success("发送成功");
        await this.phoneCodeLogin60sQ(); // 调用验证码倒计时
      } else {
        this.$message.warning(response.message);
      }
    },
    async change() {
      this.form.tel = "";
      this.form.name = "";
      this.beizu1 = false;
      this.beizu2 = false;
    },
    // 登录密码倒计时 --- 登录密码
    phoneCodeLogin60sQ() {
      // 验证码登录-获取验证码-60s倒计时
      this.phoneCodeLoginDisabledQ = true;
      let count = 60;
      // var timer = null;
      this.timer = setInterval(() => {
        count--;
        this.phoneCodeLoginTextQ = count + "s";
        if (count === 0) {
          clearInterval(this.timer);
          this.phoneCodeLoginTextQ = "重新获取";
          this.phoneCodeLoginDisabledQ = false;
        }
      }, 1000);
    },

    async handleOk() {
      let this_ = this;
      this.$refs.formAdd.validate(async (valid) => {
        if (valid) {
          let data = {
            tel: this.form.tel,
            sms: this.form.sms,
            companyId: this.companyId,
            name: this.form.name,
          };
          console.log(data);
          const res = await addChild(data);
          if (res.code == 0) {
            this.$message.success("新建成功");
            this.phoneCodeLoginTextQ = "获取验证码";
            this.phoneCodeLoginDisabledQ = false;
            this.addShow = false;
            this.form = {};
            await this.load();
          } else {
            this.$message.warning(res.message);
          }
        }
      });
    },
    handleCancel() {
      this.addShow = false;
      this.form = {};
      this.beizu1 = false;
      this.beizu2 = false;
      clearInterval(this.timer);
      this.$refs.formAdd.resetFields();
      this.phoneCodeLoginTextQ = "获取验证码";
      this.phoneCodeLoginDisabledQ = false;
      this.phoneCodeLoginDisabledQ = false;
    },
    // 移除
    move(index, record) {
      if (this.isStaff === 1) {
        this.popoverShow = true;
        this.previewIndex = index;
      } else {
        this.$message.warning("此操作需要主账号操作");
      }
    },
    async delOk(id) {
      const res = await delChild(id);
      if (res.code == 0) {
        this.$message.success("删除成功");
        this.previewIndex = "";
        this.popoverShow = false;
        await this.load();
      }
    },
    delCancel() {
      this.previewIndex = "";
      this.popoverShow = false;
    },

    getfalse() {
      this.beizu1 = false;
      this.beizu2 = false;
    },
  },
  watch: {
    "form.tel": function (newV, oldV) {
      let this_ = this;
      if (newV === "" || newV === undefined) {
        this_.beizu1 = false;
        this_.beizu2 = false;
      } else if (newV !== oldV) {
        this_.beizu1 = false;
        this_.beizu2 = false;
      }
    },
    deep: true,
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  min-height: 400px;
  //border: 10px solid #edeff2;
  .liebiao {
    width: 100%;
    border-bottom: 1px solid rgb(238, 233, 233);
    .title {
      color: $topNameColor;
      font-weight: 600;
      height: 50px;
      line-height: 50px;
      width: $space_div;
      margin: auto;
    }
  }
  .btn {
    margin: 15px 10px 10px 15px;
  }
  .table {
    width: $space_div;
    padding-top: $LineSpacing;
    padding-bottom: $LineSpacing;
    margin: auto;
  }
}
</style>
